<template>
  <div>
    <gl-title title="订单管理"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="orderId" label="订单搜索">
          <gl-list-search
            v-model="mFormData.orderId"
            placeholder="订单ID"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="title" label="商品标题">
          <gl-list-search
            v-model="mFormData.title"
            placeholder="商品标题"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="status" label="订单状态">
          <gl-option v-model="mFormData.status" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="presell" label="是否预售">
          <gl-option
            v-model="mFormData.presell"
            :list="presellEnum"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="下单时间">
          <gl-date-picker
            v-model="mFormData.dateTime"
            :startTime.sync="mFormData.startTime"
            :endTime.sync="mFormData.endTime"
          ></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="mReload">
            搜索
          </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          min-width="30"
        ></el-table-column>
        <el-table-column prop="id" label="订单ID" width="100"></el-table-column>
        <el-table-column prop="title" label="商品标题"></el-table-column>
        <el-table-column
          prop="specification"
          label="商品规格"
        ></el-table-column>
        <el-table-column prop="consignee" label="下单人昵称"></el-table-column>
        <el-table-column prop="name" label="收货人名称"></el-table-column>
        <el-table-column prop="name" label="收货人电话"></el-table-column>
        <el-table-column prop="name" label="收货人地址"></el-table-column>
        <el-table-column prop="name" label="快递单号"></el-table-column>
        <el-table-column prop="name" label="订单状态"></el-table-column>
        <el-table-column prop="name" label="订单时间"></el-table-column>
        <el-table-column prop="name" label="付款时间"></el-table-column>
        <el-table-column prop="name" label="订单金额"></el-table-column>
        <el-table-column prop="name" label="付款方式">
          <template slot-scope="scope">
            <span>{{ scope.row.paymentMethod }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" width="130px" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.native="onClickAction('statusVisible', scope.row)"
            >
              订单状态
            </el-button>
            <el-button
              type="text"
              @click.native="onClickAction('previewVisible', scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
    <status :visible.sync="statusVisible" :itemId="itemId" />
    <preview :visible.sync="previewVisible" :itemId="itemId" />
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import status from './status.vue'
import preview from './preview.vue'
export default {
  components: { status, preview },
  mixins: [ListMixin],
  data() {
    return {
      statusVisible: false,
      previewVisible: false,
      itemId: '',
      statusList: [
        { name: '全部', value: 'all' },
        { name: '待发货', value: '1' },
        { name: '已发货', value: '2' },
        { name: '售后中', value: '3' },
      ],
      presellEnum: [
        { name: '否', value: '' },
        { name: '是', value: 'yes' },
      ],
    }
  },
  mounted() {
    this.mGetListFun
    this.mGetList()
  },
  methods: {
    onClickAction(name, item) {
      this.itemId = item.id || ''
      this[name] = true
    },
  },
}
</script>

<style></style>
