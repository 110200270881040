<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
    <el-form :model="formData" ref="form" label-width="100px" size="mini" v-loading="loading">
      <el-form-item prop="consignee" label="昵称">
        <div> {{formData.consignee}} </div>
      </el-form-item>
      <el-form-item prop="phone" label="联系电话">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item prop="phone" label="支付方式">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item prop="title" label="商品标题">
        <div> {{formData.title}} </div>
      </el-form-item>
      <el-form-item prop="title" label="商品规格">
        <div> {{formData.specification}} </div>
      </el-form-item>
      <el-form-item prop="id" label="状态">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="单价">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="数量">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="优惠">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="商品总价(元)">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="运费">
        <div> {{formData.id}} </div>
      </el-form-item>
      <el-form-item prop="id" label="订单实收款">
        <div> text </div>
      </el-form-item>
      <el-form-item prop="id" label="结算金额">
        <div> 税费/成本 </div>
      </el-form-item>
      <el-form-item prop="id" label="订单编号">
        <div> text </div>
      </el-form-item>
      <el-form-item prop="id" label="订单时间">
        <div> text </div>
      </el-form-item>
      <el-form-item prop="id" label="付款时间">
        <div> text </div>
      </el-form-item>
      <el-form-item prop="id" label="确认时间">
        <div> text </div>
      </el-form-item>
    </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    itemId: {
      handler: function(newVal){
        newVal && this.visible && this.getDetail()
      }
    }
  },
  methods: {
    onSubmit(){
      
    },
    getDetail(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
      },1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style>

</style>